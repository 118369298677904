//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoadingSpinner from '@/components/LoadingSpinner'
export default {
    name: 'Inviter',
    props: ['entityId','entityName'],
    created() {
        this.isLoading = true;
        this.isEmpty = false;
        this.retrieveFriends();
    },
    components: {
        LoadingSpinner,
        Friend: () => import('@/components/Inviter/Friend'),
        EmptyMessage: () => import('@/components/EmptyMessage')
    },
    data() {
        return {
            renderDialog: false,
            page: 1,
            searchText: '',
            maxNumberOfPages: 1,
            friends: [],
            isEmpty: true,
            isLoading: true
        }
    },
    methods: {
        retrieveFriends() {
            this.$http({
                url: 'profiles/' + this.$store.state.user.id + '/friends?page=' + this.page + '&q=' + this.searchText,
                method: 'GET'
            }).then((response) => {
                if (response.data['status'] == 'friends_retrieved') {
                    this.isEmpty = false;
                    this.isLoading = false;
                    this.friends = response.data['body']['friends'];
                } else if (response.data['status'] == 'no_friends') {
                    this.isEmpty = true;
                    this.isLoading = false;
                    this.friends = [];
                } else {
                    this.renderDialog = false;
                    this.isEmpty = true;
                    this.friends = [];
                    this.$q.notify({
                        message: 'There was an error while trying to retrieve friend list.',
                        color: 'negative'
                    });
                }
            }).catch(() => {
                this.$q.notify({
                    message: 'There was an error while trying to retrieve friend list.',
                    color: 'negative'
                });
            });
        },
        showDialog() {
            this.renderDialog = !this.renderDialog;
            if (this.renderDialog == true) {
                //
            }
        }
    }
}
