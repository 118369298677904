//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Member from '@/components/Member.vue'
import Inviter from '@/components/Inviter'
import LoadingUsers from '@/components/LoadingUsers'
import EmptyMessage from '@/components/EmptyMessage'
export default {
    name: "GroupMembers",
    components: {
      Member,
      Inviter,
      LoadingUsers,
      EmptyMessage
    },
    data() {
      return {
        showInviter: false,
        searchText: '',
        currentMembers: [],
        currentPage: 1,
        maxNumberOfPages: 1,
        isLoading: false,
        isEmpty: true
      }
    },
    watch: {
      searchText: function () {
        this.loadMembers();
      }
    },
    methods: {
      loadMembers() {
        this.isLoading = true;
        this.$http({
          url: 'groups/' + this.entityId + '/members?page=' + this.currentPage + '&q=' + this.searchText,
          method: 'GET'
        }).then((response) => {
          this.isLoading = false;
          if (response.data['status'] == 'members_empty') {
            this.isEmpty = true;
            this.currentPage = [];
            this.currentPage = 0;
            this.maxNumberOfPages = 0;
          }
          if (response.data['status'] == 'members_retrieved') {
            this.isEmpty = false;
            this.currentMembers = response.data['body']['members'];
            this.maxNumberOfPages = response.data['body']['pagination']['maxNumberOfPages']
            this.currentPage = response.data['body']['pagination']['currentPage']
          }
        })
      },
      
    },
    props: [
      'entityName', 
      'entityId', 
      'isMember', 
      'isAdmin', 
      'isOwner'
    ],
    created() {
      this.loadMembers();
    }
}
