//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import Avatar from '@/components/Avatar'
export default {
    name: 'Member',
    props: [
        'member',
        'isAdmin',
        'isOwner',
        'groupId'
    ],
    components: {
      Avatar
    },
    data() {
      return {
        date_joined: moment(this.member.date_joined).format('LL'),
        openMemberOption: false,
        isKicked: false
      }
    },
    methods: {
      viewProfile() {
        this.$router.push({name:"Profile", params: {id: this.member.id}});
      },
      kick() {
          this.$q.dialog({
            title: 'Kick Member?',
            message: 'Are you sure you want to kick ' + this.member.first_name + ' from the group?',
            cancel: true,
            persistent: true
          }).onOk(() => {
            this.$http({
              url: 'groups/' + this.groupId + '/kick?user_id=' + this.member.id,
              method: 'POST',
            }).then((response) =>{
              switch (response.data['status']) {
                case 'not_owner_admin':
                  this.$q.notify({
                    message: 'You cannot kick a user.',
                    color: 'warning'
                  });
                break;
                case 'not_a_member':
                  this.$q.notify({
                    message: 'The user that you are trying to kick is not a member of this group.',
                    color: 'warning',
                    icon:'warning'
                  });
                break;
                case 'member_kicked':
                  this.$q.notify({
                    message: this.member.first_name + ' has been kicked from the group.',
                    color: 'positive',
                    icon: 'check_circle'
                  });
                  this.$emit('dirty')
                break;
              }
            }).catch((err) => {
              switch (err.response.status) {
                case 403:
                  this.$q.notify({
                    message: 'You cannot kicked an administrator or an owner of this group. You need to be the owner to do such things.',
                    color: 'primary',
                    icon: 'warning'
                  });
                break;
              }
            })
          }).onCancel(() => {
            //
          }).onDismiss(() => {
            //
          })        
        }
        ,
      promote() {
          this.$q.dialog({
            title: 'Promote to Admin?',
            message: 'Are you sure you want to promote ' + this.member.first_name + ' to be an admin of this group?',
            cancel: true,
            persistent: true
          }).onOk(() => {
            this.$http({
              url: 'groups/' + this.groupId + '/promote?user_id=' + this.member.id,
              method: 'POST',
            }).then((response) =>{
              switch (response.data['status']) {
                case 'member_promoted':
                  this.$q.notify({
                    message: this.member.first_name + ' has been promoted to administrator!',
                    color: 'positive'
                  });
                  this.$emit('dirty')
                break;
                default:
                  this.$q.notify({
                    message: 'The promotion failed. Please try again later.',
                    color: 'danger'
                  });
                break;
              }
            })
          }).onCancel(() => {
            //
          }).onDismiss(() => {
            //
          })        
        },
      demote() {
          this.$q.dialog({
            title: 'Demote to Member?',
            message: 'Are you sure you want to demote ' + this.member.first_name + ' to member?',
            cancel: true,
            persistent: true
          }).onOk(() => {
            this.$http({
              url: 'groups/' + this.groupId + '/demote?user_id=' + this.member.id,
              method: 'POST',
            }).then((response) =>{
              switch (response.data['status']) {
                case 'member_demoted':
                  this.$q.notify({
                    message: this.member.first_name + ' has been demoted to member.',
                    color: 'positive'
                  });
                  this.$emit('dirty')
                break;
                default:
                  this.$q.notify({
                    message: 'The demotion failed. Please try again later.',
                    color: 'danger'
                  });
                break;
              }
            })
          }).onCancel(() => {
            //
          }).onDismiss(() => {
            //
          })
      }
    }
}
